import React, { useRef, useState } from "react";
import { Link } from "gatsby"

import { Swiper, SwiperSlide } from "swiper/react";

import { navigate } from 'gatsby';


import { MdOutlineSwipeRight } from "react-icons/md";


import { GiFountainPen } from "@react-icons/all-files/gi/GiFountainPen";
import AniLink from "gatsby-plugin-transition-link/AniLink"

import "swiper/css";

import {Navigation } from "swiper";

export default function App(props) {

  






  return (

      <>
  <Swiper
        className='homeintroduction'
        spaceBetween={-200}

                centeredSlides={false}



        grabCursor={true}
  


initialSlide={1}
  onSlideChange={(swiperCore) => {
            const {
              activeIndex,
              snapIndex,
              previousIndex,
              realIndex,
            } = swiperCore;

window.scrollTo(0,0)


              if (swiperCore.realIndex === 2) {    navigate('/about');    };

              if (swiperCore.realIndex === 0) {    navigate('/talk');    };





        }}



        pagination={{
          clickable: true,
        }}
      >



              <SwiperSlide  className=' '> </SwiperSlide>





             <SwiperSlide className='white'> 





<div className='longPage'>








<span className='standardIntroductionText homeIntroductionText animate__animated'>

<h3  className=' animate__animated animate__fadeInUp'>Hello.</h3>
<h3  className=' animate__animated animate__fadeInDown'>I'm Eugene.</h3>

<p  className=' animate__animated animate__fadeInDown'>You may swipe right to read <AniLink swipe direction="left" to="/about">about me</AniLink>,  <AniLink swipe direction="left" to="/books">my books</AniLink> or the film <AniLink swipe direction="left" to="/sleeping-dogs">Sleeping Dogs</AniLink> —  or swipe left to <AniLink swipe direction="left" to="/talk">talk and contact me</AniLink>.</p>

</span>
<div className='iconContainer animate__animated'>

<MdOutlineSwipeRight/>
</div>


</div>
        </SwiperSlide>






















      <SwiperSlide data-swiper-autoplay="999999000" className=''> </SwiperSlide>

</Swiper>
       </> 
            
  );
}